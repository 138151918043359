import type { ChangeEvent, ReactNode } from 'react'
import castArray from 'lodash.castarray'
import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import get from 'lodash.get'

interface RadioInputProps {
  label: string | ReactNode
  name: string
  error?: string | string[]
  defaultChecked?: boolean // Uncontrolled: initial checked state
  checked?: boolean // Controlled: `checked` prop
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
}

// This component can work in both a controlled and uncontrolled manner.
//
// This is because it's common to want to use a radio outside of a standard
// form - in that case you would want to be able to control the state of the
// radio directly.
//
// Passing a `checked` prop will make the radio controlled, otherwise it will
// be uncontrolled.
//
// Integration with react-hook-form is also supported, but only works in an
// uncontrolled manner.
const RadioInput = ({
  label,
  name,
  onChange,
  defaultChecked = false, // Uncontrolled: initial checked state
  checked, // Controlled: controlled checked state
  value,
  error,
  className
}: RadioInputProps) => {
  // This is a bit convoluted, but it's so we can still use this component even outside of react-hook-form
  const formContext = useFormContext()
  const register = formContext ? formContext.register : undefined
  const { errors: contextErrors = {} } = formContext?.formState || {}

  const contextError = get(contextErrors, name)?.message as string | undefined
  const wrapContextError = contextError ? castArray(contextError) : []
  const errors = (error ? castArray(error) : []).concat(wrapContextError)

  // Register the input with Remix Hook Form if uncontrolled
  //
  // Need to remove `defaultValue` from the return value of `register` to avoid
  // React warning about setting a controlled input to an uncontrolled value.
  //
  // For some reason typescript is not seeing the defaultValue prop so need to
  // cast the value first.
  const { defaultValue, ...registeredAttrs } = register
    ? (register(name, { onChange }) as { defaultValue: any } & ReturnType<typeof register>)
    : { name, onChange }

  const dot =
    "checked:before:mx-auto checked:before:block checked:before:h-2.5 checked:before:w-2.5 checked:before:rounded-full checked:before:bg-dark checked:before:content-['']"

  return (
    <div className={className}>
      <div className="flex items-center">
        <label className="flex items-center">
          <input
            type="radio"
            className={classnames(
              'mr-2 flex h-6 w-6 flex-shrink-0 appearance-none items-center justify-center rounded-full ring-2 ring-light checked:bg-surfaceOrange',
              dot
            )}
            value={value}
            // Conditionally handle controlled vs uncontrolled behavior:
            // Use `checked` if provided (controlled), otherwise rely on `defaultChecked` and RHF (uncontrolled).
            {...(checked !== undefined
              ? { checked, onChange } // Controlled mode
              : { ...registeredAttrs, defaultChecked })} // Uncontrolled with RHF
          />
          <span className="font-medium">{label}</span>
        </label>
        <span className="flex-grow">&nbsp;</span>
      </div>
      {errors?.map(
        (error, index) =>
          error && (
            <p className="text-basePink" key={index}>
              {error}
            </p>
          )
      )}
    </div>
  )
}

export default RadioInput
